.img {
  width: 95%;
  height: 5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.img2 {
  width: 95%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.grid_judul td{
  font-size: 11px;
  border-top:2px solid black;
  border-bottom:2px solid black;
  padding-top:2px;
  padding-bottom: 2px;
  font-weight:bold;
  }
  .pindahan{
    border-bottom:1px solid black;
    padding-bottom: 1px;
    vertical-align: middle;
    }
    .iconTop{
      vertical-align: top;
      margin-top: -20px;
    }
    .datepicker {
      font-size: 1em;
      color: white;
      background-color: orange;
      border-color: orange;
    }
body {
   background-color: #f2f2f2;
    background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
    background-repeat: no-repeat;
  }
  .paragraf {
    text-indent: 10%;
  }
  .potongan {
    color: red;
  }

    
